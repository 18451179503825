html {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
}

body {
  background: #F2F2F2;
  color: #474747;
}

a {
  color: #0064D2;
}

.custom-file-input:lang(en)~.custom-file-label::after {
  content: "Choisir";
}

/* .navbar ul li a {
  color: #ffffff !important;
} */
.vh100 {
  height: 100vh;
}

h1.isHome {
  font-size: 1.8rem;
}

h1 {
  font-family: 'Fjalla One', sans-serif;
  width: fit-content;
  font-weight: 400;
  color: #666666;
  font-size: 1.4rem;
  line-height: 1.2;
}

a.btn-outline-secondary {
  color: #6c757d;
}

a.btn.btn-link:hover {
  color: #000000;
}

.navbar ul li a {
  color: #000000;
}

.greenBorder {
  border-bottom: 2px solid #30B568;
}

.redBorder {
  border-top: 2px solid #D1373C;
}

div.logoBox {
  background: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.10);
}

hr {
  background-color: #42599A;
  margin: 3px 0;
  border-width: 2px;
}

.navbar {
  padding: 0 1rem;
}

.fa-bars {
  cursor: pointer;
  color: #666666;
}

.bg-white {
  background-color: #ffffff;
}

.btn-survey {
  border-radius: 0px;
  color: #ffffff;
}

.surveySectionGrey {
  color: #666666;
}

.purpleColor {
  color: #6E2F88;
}

.surveySectionTitle {
  font-size: 1.2rem;
  margin: 0;
  color: #6E2F88;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.box {
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.10);
  padding: .4rem;
}

.boxShadow {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.10);
}

.breadcrumbs-link {
  border-radius: 0px;
  margin: 0;
  font-size: 0.9rem;
  text-align: center;
  vertical-align: middle;
  display: inline-block;
  padding: .375rem .75rem;
  color: #ffffff;
  background-color: #6E2F88;
}

.breadcrumbs-link:hover {
  color: #ffffff;
}

.breadcrumbs-link h1 {
  display: inline;
  color: #ffffff;
  font-size: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.whiteSection {
  color: #ffffff;
}

a.btn-white, a.btn-white:active, a.btn-white:focus {
  color: #F98204;
  background-color: #ffffff;
  font-weight: bold;
  text-transform: uppercase;
  border: 1px solid #aeaeae;
  border-right: none;
}

.btn-link-grey, .sectionGrayTitle {
  color: #666666;
}

.btn-link-grey:hover {
  color: #444444;
}

.instructionsBox, .container canvas.chartjs-render-monitor {
  background: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.10);
}

.accordion .card, .accordion .card-header {
  border: none;
  border-radius: 0;
  background-color: #fff;
}

.accordion .card {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.10);
}

.accordion .card-header span p {
  color: #474747;
  font-weight: bold;
}

.fontSize500 {
  font-weight: 500;
}

.modal-content {
  border-radius: 0;
  border: none;
  background: #F2F2F2;
}

.card-body h3 {
  font-size: 1.1rem;
}

.card-body {
  white-space: pre-line;
}

.custom-control-input:checked~.custom-control-label::before {
  border-color: #832D98;
  background-color: #832D98;
}

.custom-checkbox .custom-control-label::before:not(#labelcustomCheck1) {
  border-radius: 0;
}

.borderBottom {
  border-bottom: 4px solid #6E2F88;
}

.naText {
  color: #8e8e8e;
}

.btnSurveyComplete, .btnSurveyComplete:hover {
  color: #ffffff;
  margin: 0 0 1rem 0;
  padding: 0.75rem;
  width: 60%;
}

@media (max-width: 576px) {
  .btnSurveyComplete, .btnSurveyComplete:hover {
    width: 90%;
  }
}

@media (min-width: 577px) and (max-width: 991px) {
  .btnSurveyComplete, .btnSurveyComplete:hover {
    width: 75%;
  }
}

.iconSurveySuccess {
  color: #006633;
}

button .purpleColor:hover {
  color: #6E2F88;
}

/*  SideNav styling  */
#sidebar {
  width: 275px;
  position: fixed;
  top: 0;
  left: -280px;
  height: 100vh;
  z-index: 999;
  background: #fff;
  color: #666666;
  transition: all 0.3s;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
}

#sidebar.active {
  left: 0;
}

#dismiss {
  padding: 0.5rem 1rem;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.overlay {
  display: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.2);
  z-index: 998;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.overlay.active {
  display: block;
  opacity: 1;
}

#sidebar .sidebar-header {
  background: #fff;
}

#sidebar ul.components {
  padding: 20px 0;
  border-bottom: 1px solid #666666;
}

#sidebar ul li a {
  font-size: 1em;
}

a[data-toggle="collapse"] {
  position: relative;
}

ul ul a {
  font-size: 0.9em !important;
  padding-left: 30px !important;
}

ul.CTAs {
  padding: 20px;
}

ul.CTAs a {
  text-align: center;
  font-size: 0.9em !important;
  display: block;
  border-radius: 5px;
  margin-bottom: 5px;
}

a.download {
  background: #fff;
}

a.article,
a.article:hover {
  color: #485654 !important;
}

.btn-toolbar button.paginationItem-active {
  background-color: #990000;
  color: #ffffff;
  font-weight: 500;
  border-radius: 0;
}

.btn-toolbar button.paginationItem {
  color: #666666;
  font-weight: 500;
  border-radius: 0;
}

.rightDivider {
  border-right: 1px solid #ccc;
  margin: 0.5rem 0;
}

span.fa-caret-down {
  padding-left: 5px;
}

.btn.btn-link.btn-link-grey:focus:hover {
  text-decoration: none;
}

.btn.btn-link.btn-link-grey:hover {
  text-decoration: underline;
}

.btn.btn-link.btn-link-grey {
  text-decoration: none;
}

.dropdown-menu {
  min-width: 12rem;
  top: 94%;
  border-radius: 0;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.10);
}

.dropdown-menu .btn {
  text-align: left;
}

.dropdown-item-link {
  display: block;
  width: 100%;
}

.dropdown-item-link.btn-link {
  color: black;
}

.nav-plan-week.nav-pills .nav-link.active, .nav-plan-week.nav-pills .show>.nav-link {
  color: #6E2F88;
  background-color: #ffffff;
  font-weight: bold;
  border-bottom: 1px solid #ffffff;
  margin-bottom: 1px;
}

.nav-plan-week.nav-pills .nav-link {
  color: #000;
  background-color: #E0E0E0;
  font-weight: bold;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #989898;
  cursor: pointer;
}

.nav-link:not(.active):hover {
  background-color: #F9F4FA;
}

.nav-link.completedWeek:not(.active) {
  background-color: #6F2E88;
  color: #FFFFFF;
}

.tab-content, .tabPanelFooter {
  background-color: #ffffff;
}

.tabPanelFooter {
  border-top: 1px solid #E0E0E0;
}

.btn-outline-purple {
  color: #6E2F88;
  border-color: #6E2F88;
  border-radius: 0;
}

.btn-outline-purple:hover {
  color: #fff;
  background-color: #6E2F88;
  border-color: #6E2F88;
}

.btn-outline-purple:not(:disabled):not(.disabled).active, .btn-outline-purple:not(:disabled):not(.disabled):active, .show>.btn-outline-purple.dropdown-toggle {
  color: #fff;
  background-color: #6E2F88;
  border-color: #6E2F88;
}

.input-group-append .btn-outline-purple {
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
}

.input-group-text {
  background-color: #F9F4FA;
  padding: 1.375rem 1rem;
}

.custom-control-label::before, .custom-control-label::after {
  top: .15rem;
  left: -1.5rem;
  width: 1.4rem;
  height: 1.4rem;
}

.custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before {
  background-color: rgba(110, 47, 136, .5);
}

.plan-formSection {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.10);
  background-color: #ffffff;
}

textarea {
  resize: none;
}

.fa-trash {
  background: none;
  border: none;
}

.fa-trash:hover {
  color: #ad0c0c;
  cursor: pointer;
}

.emoji-mart {
  position: absolute;
  z-index: 2;
  top: -162px;
  left: 50px;
  width: 285px !important;
}

.emoji-mart-search-icon {
  top: 4px !important;
}

.emojiBtn {
  cursor: pointer;
}

.emoji-mart-bar {
  display: none;
}

/* #sp_120 h4, #sp_121 h4, #sp_122 h4, #sp_123 h4, #sp_124 h4, #sp_125 h4, #sp_126 h4 {
  border-left: 2px solid #3463ff;
}

#sp_127 h4, #sp_128 h4, #sp_129 h4, #sp_130 h4, #sp_131 h4, #sp_132 h4 {
  border-left: 2px solid #27ba69;
}

#sp_133 h4, #sp_134 h4, #sp_135 h4, #sp_136 h4, #sp_137 h4, #sp_138 h4, #sp_139 h4 {
  border-left: 2px solid #ef3f45;
} */
textarea.checked {
  background-color: #F9F4FA;
}

.commentSection {
  border-top: 1px solid #ced4da
}

.commentairesEleves {
  border-bottom: 1px solid #ced4da
}

.icon-bc {
  color: #000000;
  background-color: #e0e0e0;
  display: inline-block;
  border-radius: 60px;
  box-shadow: 0px 0px 2px #888;
  padding: 0.5em;
}

.iconStatic-bc {
  color: #000000;
  background-color: #e0e0e0;
  display: inline-block;
  border-radius: 60px;
  box-shadow: 0px 0px 2px #888;
  padding: 0.5em;
}

.icon-bc:hover {
  color: #ffffff;
  background-color: #6E2F88;
}

/* Survey js styles */
.sv_main, .sv_main .sv_custom_header, .sv_main .card-footer, .sv_main .sv_container {
  background-color: #ffffff;
}

.sv_main .sv_container {
  color: #474747;
  margin-bottom: 2rem;
}

.sv_main {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.10);
}

.sv_container h5 {
  font-size: 1rem;
}

.sv_bootstrap_css .sv_qstn {
  padding: 1.5em 1em;
}

.sv_container h3 {
  padding: .75rem;
}

.sv_main input[type="button"].diagnostiqueCompleteBtn, .sv_main input[type="button"].diagnostiquePrevBtn, .sv_main input[type="button"].diagnostiqueNextBtn {
  background-color: #812E96;
  color: #ffffff;
  border-radius: 0;
  padding: .375rem .75rem;
  font-weight: 400;
}

.sv_main .panel-footer.card-footer {
  height: 60px;
}

.sv_main .panel-footer.card-footer input {
  margin-right: 0 !important;
}

.sv_main input[type="button"].diagnostiqueCompleteBtn:hover, .sv_main input[type="button"].diagnostiquePrevBtn:hover, .sv_main input[type="button"].diagnostiqueNextBtn:hover {
  background-color: #6E2F88;
}

.sv_main input[type="button"].journalCompleteBtn, .sv_main input[type="button"].journalPrevBtn, .sv_main input[type="button"].journalNextBtn {
  background-color: #812E96;
  color: #ffffff;
  border-radius: 0;
  padding: .375rem .75rem;
  font-weight: 400;
}

.btnPlanSauvegarder {
  background-color: #812E96;
  color: #ffffff;
  border-radius: 0;
}

.btnPlanSauvegarder:hover {
  background-color: #6E2F88;
  color: #ffffff;
}

.sv_main input[type="button"].journalCompleteBtn:hover, .sv_main input[type="button"].journalPrevBtn:hover, .sv_main input[type="button"].journalNextBtn:hover {
  background-color: #6E2F88;
}

.sv_container fieldset {
  font-size: .95rem;
}

.sv_main .sv_container h4 {
  padding: .75rem;
  border-bottom: 1px solid rgba(0, 0, 0, .125);
}

.sv_main .sv_container .panel-heading.card-header {
  padding: .75rem;
  border-bottom: 1px solid rgba(0, 0, 0, .125);
  background-color: #ffffff;
}

.sv_main .sv_container .panel-heading.card-header h3 {
  padding: 0;
  font-size: 1.5rem;
  margin: 0;
}

p.completedSurveyText {
  font-weight: 500;
  text-align: center;
  font-size: 1.3rem;
  padding: .75rem;
}

.thumbnail {
  position: relative;
}

.caption {
  position: absolute;
  top: 45%;
  left: 0;
  width: 100%;
}

.sv_main .sv_container .panel-footer .diagnostiqueNextBtn, .sv_main .sv_container .panel-footer .journalCompleteBtn, .sv_main .sv_container .panel-footer .journalNextBtn, .sv_main .sv_container .panel-footer .diagnostiqueCompleteBtn {
  float: right;
}

.studentHeader {
  color: #000000;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 500;
  font-size: 1.6rem;
}

.accordion button p {
  color: #000000;
  font-weight: 500;
}

a.skip-main {
  left: -999px;
  position: absolute;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  z-index: -999;
}

a.skip-main:focus, a.skip-main:active {
  color: #fff;
  background-color: #6E2F88;
  left: auto;
  top: auto;
  width: 30%;
  height: auto;
  overflow: auto;
  margin: 10px 35%;
  padding: 5px;
  text-align: center;
  font-size: 1.2em;
  z-index: 999;
}

.custom-file-input~.custom-file-label::after {
  content: "Parcourir";
}